import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faAndroid } from '@fortawesome/free-brands-svg-icons';

class TimeWinning extends React.Component {
  render() {
    const {
      title,
      subtitle,
      androidURL,
      iPhoneURL,
      downloadHeader,
      children,
      imageURL,
    } = this.props;
    return (
      <section className="home-about-area section-gap bg-grey">
        <div className="container">
          <div className="row align-items center">
            <div className="col-lg-6">
              <h6 className="text-uppercase text-orange">{title}</h6>
              <h2 className="mb-5 mt-3">{subtitle}</h2>
              <article className="test-justify">{children}</article>
              {(androidURL || iPhoneURL) && (
                <>
                  <h4 className="text-uppercase mt-5">{downloadHeader}</h4>
                  {androidURL && (
                    <>
                      <a
                        href={androidURL}
                        className="primary-btn squire mx-auto btn-lg mt-20"
                        target={'_blank'}
                      >
                        <FontAwesomeIcon icon={faAndroid} />
                        &nbsp; Android
                      </a>
                      &nbsp;
                    </>
                  )}
                  {iPhoneURL && (
                    <a
                      href={`${iPhoneURL}`}
                      className="primary-btn squire mx-auto btn-lg mt-20"
                    >
                      <FontAwesomeIcon icon={faApple} />
                      &nbsp; iPhone
                    </a>
                  )}
                </>
              )}
            </div>
            <div className="col-lg-6">
              <img
                src={imageURL}
                className={'mt-5'}
                width={'100%'}
                alt={"Téléchargez l'application Oaza"}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TimeWinning;
