import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SubBanner extends React.Component {
  render() {
    const { icon, title, children, href, className } = this.props;
    if (!icon && !title && !children) return null;
    return (
      <div className={`col-lg-6 col-12 ${className || ''}`}>
        {title && (
          <>
            <div className="no-click-primary-btn custom-btn mx-auto mt-20" href={href}>
              {icon && (
                <>
                  <FontAwesomeIcon icon={icon} className="mr-2" />
                  &nbsp;
                </>
              )}
              {title && title.toUpperCase()}
            </div>
            <br />
            <br />
          </>
        )}
        {children}
      </div>
    );
  }
}

export default SubBanner;
