import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-grid.css';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { faStoreAlt } from '@fortawesome/free-solid-svg-icons';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import '../styles/main.scss';
import Banner from '../components/Banner';
import SubBanner from '../components/SubBanner';
import About from '../components/About';
import TimeWinning from '../components/TimeWinning';
import Contact from '../components/Contact';
import SubAbout from '../components/SubAbout';
import SEO from '../components/seo';

const menus = [
  {
    title: 'Officines',
  },
  {
    title: 'Candidats',
  },
  {
    title: 'Qui sommes-nous ?',
  },
  {
    title: 'Contact',
  },
];

class BlogIndex extends React.Component {
  render() {
    const {
      data: {
        site: {
          siteMetadata: {
            download: { android: androidURL, iphone: iphoneURL },
            social: {
              facebook: facebookURL,
              linkedin: linkedinURL,
              twitter: twitterURL,
              phoneNumber,
              contactEmail,
              servicesURL,
            },
          },
        },
      },
      data: {
        logo: { publicURL: logoURL },
      },
      data: {
        presentation: { publicURL: presentationURL },
      },
      data: {
        doctor: { publicURL: doctorURL },
      },
      data: {
        mockup: { publicURL: mockupURL },
      },
      data: {
        site: {
          siteMetadata: { title: siteTitle },
        },
      },
    } = this.props;

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        menus={menus}
        imageURL={logoURL}
        facebookURL={facebookURL}
        linkedinURL={linkedinURL}
        twitterURL={twitterURL}
        phoneNumber={phoneNumber}
        contactEmail={contactEmail}
        servicesURL={servicesURL}
      >
        <SEO
          title="Oaza"
          keywords={[`oaza`, `pharmacie`, `officine`, `remplacement`]}
        />
        <Banner
          title={
            'Solution RH dédiée au secteur Café, Hôtellerie et Restauration'
          }
          imageURL={presentationURL}
        >
          <SubBanner
            icon={faStoreAlt}
            title={'établissements'}
            className={'mb-4'}
          >
            Recrutez et gérez vos remplacements urgents en un clic
          </SubBanner>
          <SubBanner icon={faAddressCard} title={'Candidats'}>
            Inscrivez-vous et trouver un emploi ou des missions de remplacement
          </SubBanner>
        </Banner>
        <About
          title="établissements"
          subtitle="L'application OAZA a pour vocation la gestion des remplacements et gestion de candidatures à l'emploi dans le secteur Café, Hôtellerie et Restauration."
          // imageURL={doctorURL}
          className="section-gap mt-5"
        >
          <SubAbout
            title={'Recrutement:'}
            items={[
              'Accédez à un vivier de candidats actifs',
              'Visualisez un mini CV qui reprend expériences et diplômes',
              'Accédez à des candidatures qualifiées via une gestion électronique des documents essentielle pour réaliser une DPAE',
            ]}
          />
          <SubAbout
            title={'Gestion des remplacements urgents et imprévus :'}
            items={[
              'Intégrez des candidats dans votre pool',
              'Renforcez votre équipe via la plateforme de recrutement',
              'Emettez des offres de remplacements en quelques secondes',
              'Utilisez l’application mobile pour gérer vos absences',
            ]}
          />
        </About>
        <TimeWinning
          title={
            'Oaza, plateforme de recrutement dédiée au secteur Café, Hôtellerie et Restauration'
          }
          subtitle={''}
          downloadHeader={"Téléchargez gratuitement l'application"}
          androidURL={androidURL}
          iPhoneURL={iphoneURL}
          imageURL={mockupURL}
        >
          <SubAbout
            items={[
              'Renseignez votre profil',
              'Intégrez via l’application mobile les documents préalables à l’embauche',
              'Définissez votre recherche',
              'Définissez votre zone d’intervention',
              'Donnez de la force à votre profil et devenez expert',
              'Trouvez des missions de remplacements urgents et imprévus',
            ]}
          />
          <p>
            <strong>L’application est gratuite pour les candidats</strong>
          </p>
        </TimeWinning>
        {/*<Contact />*/}
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        download {
          android
          iphone
        }
        social {
          facebook
          linkedin
          twitter
          phoneNumber
          contactEmail
          servicesURL
        }
      }
    }
    logo: file(relativePath: { eq: "logo-oaza-texte.png" }) {
      publicURL
    }
    presentation: file(relativePath: { eq: "presentation-oaza.png" }) {
      publicURL
    }
    doctor: file(relativePath: { eq: "docteur.png" }) {
      publicURL
    }
    mockup: file(relativePath: { eq: "mockup-black.png" }) {
      publicURL
    }
  }
`;
