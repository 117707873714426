import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';

class Contact extends Component {
  render() {
    return (
      <section className="container-fluid section-gap contact-section text-center bg-gradient-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <h2 className="mb-5 text-uppercase text-white">
                Vous souhaitez en savoir plus ? Vous inscrire ?
              </h2>
            </div>
            <div className="col-lg-6 offset-lg-3">
              <a
                href=""
                className="primary-btn custom-btn mx-auto btn-lg mt-20"
              >
                <FontAwesomeIcon icon={faEnvelopeOpen} /> Contactez-nous
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
