import React from 'react';

class About extends React.Component {
  render() {
    const { title, subtitle, children, imageURL } = this.props;
    let widthGridSize = 10;
    if (imageURL) {
      widthGridSize = 7;
    }

    return (
      <section className="section-gap mt-5">
        <div className="container">
          <div className="row align-items-center">
            {imageURL && (
              <div className="col-lg-4 offset-lg-0 col-8 offset-2 justify-content-center align-items-center d-flex mb-5">
                <img width={'100%'} src={`${imageURL}`} />
              </div>
            )}
            <div className={`col-lg-${widthGridSize} offset-lg-1`}>
              <h6 className="text-uppercase text-orange">{title}</h6>
              <h2 className="text-justify mb-5 mt-3">{subtitle}</h2>
              <article className="text-justify">{children}</article>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
