import React from 'react';

class Banner extends React.Component {
  render() {
    const { title, children, imageURL } = this.props;
    console.log('imageURL:', imageURL);
    return (
      <section className="bg-gradient-section pt-5">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="banner-content col-lg-7 col-md-12">
              <h1>{title}</h1>
              <div className="row">{children}</div>
            </div>
            <img
              src={`${imageURL}`}
              width={'100%'}
              className="col-lg-5 col-8 offset-2 offset-lg-0"
            />
          </div>
        </div>
      </section>
    );
  }
}

export default Banner;
