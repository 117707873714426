import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

class SubAbout extends React.Component {
  render() {
    const { title, items } = this.props;
    return (
      <>
        {title && <h2>{title}</h2>}
        <ul className="list-items">
          {items.map((item, index) => {
            return (
              <li key={index}>
                <strong>
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className={'mr-2 text-orange'}
                  />
                  {item}
                </strong>
              </li>
            );
          })}
        </ul>
      </>
    );
  }
}

export default SubAbout;
